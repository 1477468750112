
import { MODULE_TYPES, ModuleItem } from "@/store/constants";
import Vue, { PropType } from "vue";
import { HorseItem } from "@/views/Horses.vue";
import BatchOperations from "@/components/BatchOperations.vue";
import AddDoneAppointment
  from "@/components/modules/health/healthBatchTypes/AddDoneAppointment.vue";
import AddVermifuge from "@/components/modules/health/healthBatchTypes/AddVermifuge.vue";
import DoneAppointmentConfirmation
  from "@/components/modules/health/healthBatchTypes/DoneAppointmentConfirmation.vue";
import { ExamGlandersEIA, HealthReport, Vaccine, Vermifuge } from "@/store/modules/health";
import VermifugeConfirmation
  from "@/components/modules/health/healthBatchTypes/VermifugeConfirmation.vue";
import { LocalizedSelectItem } from "@/helpers/types";
import AddVaccine from "@/components/modules/health/healthBatchTypes/AddVaccine.vue";
import VaccineConfirmation
  from "@/components/modules/health/healthBatchTypes/VaccineConfirmation.vue";
import { Validation } from "vuelidate";
import AddExamGlandersEIA
  from "@/components/modules/health/healthBatchTypes/AddExamGlandersEIA.vue";
import ExamGlandersEIAConfirmation
  from "@/components/modules/health/healthBatchTypes/ExamGlandersEIAConfirmation.vue";
import { TYPE } from "@/store/modules/notification";
import AddWeight from "@/components/modules/health/healthBatchTypes/AddWeight.vue";
import WeightConfirmation
  from "@/components/modules/health/healthBatchTypes/WeightConfirmation.vue";
import { HorseWeight } from "@/store/modules/horse";

function initialState() {
  return {
    CATEGORY,
    category: '',
    eia: {
      id: '',
      dateStart: '',
      dateEnd: '',
      laboratory: '',
      examNumber: '',
      doctor: {
        name: '',
        phone: ''
      }
    },
    glanders: {
      id: '',
      dateStart: '',
      dateEnd: '',
      laboratory: '',
      examNumber: '',
      doctor: {
        name: '',
        phone: ''
      }
    },
    vaccine: {
      id: '',
      dateStart: '',
      dateEnd: '',
      product: '',
      batchNumber: '',
      protectedAgainst: [],
      doctor: {
        name: '',
        phone: ''
      }
    },
    healthReport: {
      id: '',
      type: 'health-module-screen.health-report.form.types.veterinary',
      dateStart: '',
      dateCreated: '',
      dateEnd: null,
      lastEntry: {
        dateStart: '',
        dateCreated: '',
        anamnesis: '',
        conclusion: '',
        doctorName: '',
        doctorPhone: '',
        clinicalExam: {
          description: '',
          exams: []
        },
        treatments: []
      },
      entries: []
    },
    horseWeight: {
      weight: '',
      weighingType: '',
      weightInDate: '',
      conclusion: ''
    },
    vermifuge: {
      id: '',
      dateStart: '',
      dateEnd: '',
      drug: '',
      activePrinciple: [],
      doctor: {
        name: '',
        phone: ''
      }
    },
    valid: false,
    loading: false
  };
}

enum CATEGORY {
  PROCEDURE = 'health-batch-card.category.register-past-appointment',
  VERMIFUGE = 'health-batch-card.category.vermifuge',
  VACCINE = 'health-batch-card.category.vaccine',
  GLANDERS = 'health-batch-card.category.glanders',
  EIA = 'health-batch-card.category.eia',
  WEIGHT = 'health-batch-card.category.weight'
}

interface Data {
  CATEGORY: Record<string, unknown>;
  valid: boolean;
  loading: boolean;
  category: string;
  vaccine: Vaccine;
  healthReport: HealthReport;
  vermifuge: Vermifuge;
  horseWeight: HorseWeight;
  eia: ExamGlandersEIA;
  glanders: ExamGlandersEIA;
}

interface Methods {
  confirmBatch(): void;

  confirmWeightIn(): void;

  confirmBatchHealthReport(): void;

  confirmBatchVermifuge(): void;

  confirmBatchVaccine(): void;

  confirmBatchEIA(): void;

  confirmBatchGlanders(): void;

  removeHorse(id: number): void;

  validateForms(): void;

  getHorsesIds(): string[];

  resetData(): void;

  emitConfirmation(message: string): void;

  resetAdditionFields(): void;
}

interface Computed {
  healthModule: ModuleItem;
  localizedHealthCategoryList: LocalizedSelectItem[];
}

interface Props {
  horseItems: HorseItem[];
  showNameBtn: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HealthBatch',
  data: function () {
    return initialState();
  },
  components: {
    WeightConfirmation,
    AddWeight,
    ExamGlandersEIAConfirmation,
    AddExamGlandersEIA,
    VaccineConfirmation,
    AddVaccine,
    BatchOperations,
    AddDoneAppointment,
    DoneAppointmentConfirmation,
    AddVermifuge,
    VermifugeConfirmation
  },
  methods: {
    resetAdditionFields() {
      for (const item of this.horseItems) {
        item.additionalField = '';
      }
    },
    emitConfirmation(message) {
      this.resetData();
      this.$store.commit('NotificationModule/add', {
        msg: this.$t(message),
        type: TYPE.SUCCESS
      });
      this.$emit('confirmed-batch');
    },
    resetData() {
      Object.assign(this.$data, initialState());
    },
    removeHorse(id) {
      this.$emit('remove-horse', id);
    },
    confirmBatch() {
      this.loading = true;
      switch (this.category) {
        case CATEGORY.PROCEDURE: {
          this.confirmBatchHealthReport();
          break;
        }
        case CATEGORY.VERMIFUGE: {
          this.confirmBatchVermifuge();
          break;
        }
        case CATEGORY.VACCINE: {
          this.confirmBatchVaccine();
          break;
        }
        case CATEGORY.EIA: {
          this.confirmBatchEIA();
          break;
        }
        case CATEGORY.GLANDERS: {
          this.confirmBatchGlanders();
          break;
        }
        case CATEGORY.WEIGHT: {
          this.confirmWeightIn();
          break;
        }
      }
      (this.$refs[this.category] as Validation).$v.$reset();
    },
    getHorsesIds() {
      const horsesId = [];
      for (const horseItem of this.horseItems) {
        const horseId = horseItem.horse.id;
        horsesId.push(horseId);
      }
      return horsesId;
    },
    confirmBatchEIA() {
      this.$store
        .dispatch('HealthModule/addEIAToHorses', {
          horses: this.horseItems,
          eia: this.eia
        })
        .then(() => {
          this.resetAdditionFields();
          this.emitConfirmation('health-batch-card.eia.saved-confirmation');
        })
        .catch((error) => {
          this.$rollbar.error(error);
          this.loading = false;
        });
    },
    confirmWeightIn() {
      let horse = this.horseItems[0].horse;
      if (!horse.weights) {
        horse.weights = [];
      }
      horse.weights.push(this.horseWeight);
      this.$store
        .dispatch('HorseModule/updateHorse', {
          horse: horse
        })
        .then(() => {
          this.loading = false;
          this.emitConfirmation('health-batch-card.weight.saved-confirmation');
        })
        .catch((error) => {
          this.$rollbar.error(error);
          this.loading = false;
        });
    },
    confirmBatchGlanders() {
      this.$store
        .dispatch('HealthModule/addGlandersToHorses', {
          horses: this.horseItems,
          glanders: this.glanders
        })
        .then(() => {
          this.resetAdditionFields();
          this.emitConfirmation(
            'health-batch-card.glanders.saved-confirmation'
          );
        })
        .catch((error) => {
          this.$rollbar.error(error);
          this.loading = false;
        });
    },
    confirmBatchVaccine() {
      const horsesId = this.getHorsesIds();
      this.$store
        .dispatch('HealthModule/addVaccineToHorses', {
          horsesId: horsesId,
          vaccine: this.vaccine
        })
        .then(() => {
          this.emitConfirmation('health-batch-card.vaccine.saved-confirmation');
        })
        .catch((error) => {
          this.$rollbar.error(error);
          this.loading = false;
        });
    },
    confirmBatchVermifuge() {
      const horsesId = this.getHorsesIds();
      this.$store
        .dispatch('HealthModule/addVermifugeToHorses', {
          horsesId: horsesId,
          vermifuge: this.vermifuge
        })
        .then(() => {
          this.emitConfirmation(
            'health-batch-card.vermifuge.saved-confirmation'
          );
        })
        .catch((error) => {
          this.$rollbar.error(error);
          this.loading = false;
        });
    },
    confirmBatchHealthReport() {
      const horsesId = this.getHorsesIds();
      this.healthReport.entries.push(this.healthReport.lastEntry);
      this.$store
        .dispatch('HealthModule/addHealthReportToHorses', {
          horsesId: horsesId,
          healthReport: this.healthReport
        })
        .then(() => {
          this.emitConfirmation(
            'health-batch-card.done-appointment.saved-confirmation'
          );
        })
        .catch((error) => {
          this.$rollbar.error(error);
          this.loading = false;
        });
    },
    validateForms() {
      if (!this.category) {
        this.valid = false;
        return;
      }
      (this.$refs[this.category] as Validation).$v.$touch();
      if ((this.$refs[this.category] as Vue).$refs.datePicker as Validation) {
        (
          (this.$refs[this.category] as Vue).$refs.datePicker as Validation
        ).$v.$touch();
      }
      if (
        (this.$refs[this.category] as Vue).$refs.endDatePicker as Validation
      ) {
        (
          (this.$refs[this.category] as Vue).$refs.endDatePicker as Validation
        ).$v.$touch();
      }
      this.valid = !(this.$refs[this.category] as Validation).$v.$invalid;
    }
  },
  computed: {
    healthModule() {
      return MODULE_TYPES.HEALTH;
    },
    localizedHealthCategoryList() {
      const LocalizedList: LocalizedSelectItem[] = [];
      let categories = Object.values(CATEGORY);
      if (this.horseItems.length > 1) {
        categories = categories.filter(
          (category) => category !== 'health-batch-card.category.weight'
        );
      }
      for (const item of categories) {
        let localizedDisease: LocalizedSelectItem = {
          label: this.$t(item),
          value: item
        };
        LocalizedList.push(localizedDisease);
      }
      return LocalizedList;
    }
  },
  props: {
    horseItems: {
      type: Array as PropType<HorseItem[]>,
      required: true
    },
    showNameBtn: {
      type: Boolean,
      default: true
    }
  }
});
