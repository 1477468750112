
import { MODULE_TYPES, ModuleItem } from "@/store/constants";
import Vue, { PropType } from "vue";
import { HorseItem } from "@/views/Horses.vue";
import FoodTable from "@/components/modules/food/FoodTable.vue";
import FoodForm from "@/components/modules/food/FoodForm.vue";
import BatchOperations from "@/components/BatchOperations.vue";
import { Diet, FoodItem } from "@/store/modules/food";
import { Validation } from "vuelidate";
import { TYPE } from "@/store/modules/notification";
import { mdiClose, mdiPlus } from "@mdi/js";

interface Props {
  horseItems: HorseItem[];
  showNameBtn: boolean;
}

interface Methods {
  addNewItem(): void;
  confirmBatch(): void;
  removeHorse(id: number): void;
  removeFood(id: number): void;
  validateForm(): void;
  isFormInvalidAndDirty(index: number): boolean;
}

interface Data {
  valid: boolean;
  diet: Diet;
  loading: boolean;
  foodUnitList: string[];
  selectedPanel: number;
  foodItemsId: number;
}

interface Computed {
  food: ModuleItem;
  mdiClose: string;
  mdiPlus: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FoodBatch',
  data: () => ({
    valid: false,
    loading: false,
    selectedPanel: 0,
    foodItemsId: 0,
    diet: {
      id: '1',
      dateStart: new Date(),
      foodItems: []
    },
    foodUnitList: ['mg', 'g', 'kg', 'ml', 'l']
  }),
  components: {
    FoodTable,
    BatchOperations,
    FoodForm
  },
  methods: {
    validateForm() {
      if (!this.diet.foodItems.length) {
        this.valid = false;
        return;
      }
      (this.$refs.foodForm as Vue[]).forEach((foodForm) => {
        (foodForm as Validation).$v.$touch();
        if (foodForm.$refs.datePicker as Validation) {
          (foodForm.$refs.datePicker as Validation).$v.$touch();
        }

        if (foodForm.$v.$invalid) {
          this.valid = false;
        }
      });
    },
    isFormInvalidAndDirty(index) {
      if (!this.$refs.foodForm) {
        return false;
      }
      if (!(this.$refs.foodForm as never)[index]) {
        return false;
      }
      const foodRefInvalid = (
        (this.$refs.foodForm as Vue[])[index] as Validation
      ).$v.$invalid;
      const foodDirty = ((this.$refs.foodForm as Vue[])[index] as Validation).$v
        .$dirty;
      return foodRefInvalid && foodDirty;
    },
    confirmBatch() {
      this.loading = true;
      const horsesId = [];
      for (const horseItem of this.horseItems) {
        const horseId = horseItem.horse.id;
        horsesId.push(horseId);
      }
      this.$store
        .dispatch('FoodModule/addDietToHorses', {
          horsesId: horsesId,
          diet: this.diet
        })
        .then(() => {
          this.diet = {
            id: '1',
            dateStart: new Date(),
            foodItems: []
          };
          this.$store.commit('NotificationModule/add', {
            msg: this.$t('food-batch-card.snackbar-success'),
            type: TYPE.SUCCESS
          });
          this.loading = false;
          this.$emit('confirmed-batch');

          (this.$refs.foodForm as Vue[]).forEach((foodForm) => {
            (foodForm as Validation).$v.$reset();
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$emit('error-batch', error);
        });
    },
    removeHorse(id) {
      this.$emit('remove-horse', id);
    },
    removeFood(id) {
      this.diet.foodItems.splice(id, 1);
    },
    addNewItem() {
      if (this.diet.foodItems.length) {
        const index = this.diet.foodItems.length - 1;
        ((this.$refs.foodForm as Vue[])[index] as Validation).$v.$touch();

        if (
          (this.$refs.foodForm as Vue[])[index].$refs.datePicker as Validation
        ) {
          (
            (this.$refs.foodForm as Vue[])[index].$refs.datePicker as Validation
          ).$v.$touch();
        }
      }
      const foodItem: FoodItem = {} as FoodItem;
      foodItem.id = this.diet.foodItems.length;
      this.diet.foodItems.push(foodItem);
      this.selectedPanel = this.diet.foodItems.length;
    }
  },
  props: {
    horseItems: {
      type: Array as PropType<HorseItem[]>,
      required: true
    },
    showNameBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    mdiClose() {
      return mdiClose;
    },
    mdiPlus() {
      return mdiPlus;
    },
    food() {
      return MODULE_TYPES.FOOD;
    }
  }
});
