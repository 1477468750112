
import Vue, { PropType } from 'vue';
import { ExamGlandersEIA } from '@/store/modules/health';
import { HorseItem } from '@/views/Horses.vue';
import { mdiPhone } from '@mdi/js';

interface Props {
  exam: ExamGlandersEIA;
  horseItems: HorseItem[];
  title: string;
}

interface Computed {
  mdiPhone: string;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'ExamGlandersEIAConfirmation',
  computed: {
    mdiPhone() {
      return mdiPhone;
    }
  },
  props: {
    exam: {
      type: Object as PropType<ExamGlandersEIA>,
      required: true
    },
    horseItems: {
      type: Array as PropType<HorseItem[]>,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
});
