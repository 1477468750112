
import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { ExamGlandersEIA } from '@/store/modules/health';
import { HorseItem } from '@/views/Horses.vue';
import AvatarImageKit from '@/components/AvatarImageKit.vue';
import vuelidateMixin from '@/helpers/vuelidateMixin';
import DatePicker from '@/components/horse/DatePicker.vue';
import { mdiHorseVariant, mdiPhone } from '@mdi/js';
import { mask } from 'vue-the-mask';

interface Computed {
  localExamGlandersEIA: ExamGlandersEIA;
  mdiPhone: string;
  mdiHorseVariant: string;
}

interface Props {
  value: ExamGlandersEIA;
  horseItems: HorseItem[];
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'AddExamGlandersEIA',
  components: {
    AvatarImageKit,
    DatePicker
  },
  directives: { mask },
  mixins: [validationMixin, vuelidateMixin],
  validations: {
    localExamGlandersEIA: {
      laboratory: { required }
    }
  },
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    localExamGlandersEIA: {
      get() {
        return this.value;
      }
    }
  },
  props: {
    value: {
      type: Object as PropType<ExamGlandersEIA>,
      required: true
    },
    horseItems: {
      type: Array as PropType<HorseItem[]>,
      required: true
    }
  },
  watch: {
    localExamGlandersEIA: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true
    },
    StartDateDisplay: {
      handler(newVal) {
        if (newVal && this.localExamGlandersEIA.dateEnd) {
          const appliedDate = new Date(this.localExamGlandersEIA.dateStart);
          const expiryDate = new Date(this.localExamGlandersEIA.dateEnd);
          if (appliedDate > expiryDate) {
            this.localExamGlandersEIA.dateEnd = '';
          }
        }
      }
    }
  }
});
