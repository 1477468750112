
import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { Vermifuge } from '@/store/modules/health';
import { LocalizedSelectItem } from '@/helpers/types';
import vuelidateMixin from '@/helpers/vuelidateMixin';
import { mdiCalendar, mdiPhone } from '@mdi/js';
import { mask } from 'vue-the-mask';

enum ACTIVE_PRINCIPLES {
  IVERMECTIN = 'health-batch-card.vermifuge.active-principles.ivermectin',
  PIRANTEL_PALMOATE = 'health-batch-card.vermifuge.active-principles.pirantel-palmoate',
  MOXIDECTIN = 'health-batch-card.vermifuge.active-principles.moxidectin',
  PRAZIQUANTEL = 'health-batch-card.vermifuge.active-principles.praziquantel',
  ABAMECTIN = 'health-batch-card.vermifuge.active-principles.abamectin',
  MEBENDAZOLE = 'health-batch-card.vermifuge.active-principles.mebendazole',
  TRICHLORFON = 'health-batch-card.vermifuge.active-principles.trichlorfon'
}

interface Data {
  ACTIVE_PRINCIPLES: Record<string, unknown>;
  vermifugeDateDisplay: string;
  vermifugeExpiryDateDisplay: string;
  useOtherActivePrinciple: boolean;
  otherActivePrinciple: string;
}

interface Methods {
  selectDateVermifuge(): void;

  selectExpiryDateVermifuge(): void;
}

interface Computed {
  localVermifuge: Vermifuge;
  localizedActivePrincipleList: LocalizedSelectItem[];
  mdiCalendar: string;
  mdiPhone: string;
}

interface Props {
  value: Vermifuge;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AddVermifuge',
  mixins: [validationMixin, vuelidateMixin],
  directives: { mask },
  validations: {
    localVermifuge: {
      drug: { required },
      activePrinciple: { required }
    },
    vermifugeDateDisplay: { required },
    vermifugeExpiryDateDisplay: { required },
    otherActivePrinciple: {
      required: requiredIf(function (vm) {
        return vm.useOtherActivePrinciple;
      })
    }
  },
  data: () => ({
    ACTIVE_PRINCIPLES,
    vermifugeDateDisplay: '',
    vermifugeExpiryDateDisplay: '',
    useOtherActivePrinciple: false,
    otherActivePrinciple: ''
  }),
  methods: {
    selectDateVermifuge() {
      this.vermifugeDateDisplay = this.$dayjs(
        this.localVermifuge.dateStart
      ).format('L');
    },
    selectExpiryDateVermifuge() {
      this.vermifugeExpiryDateDisplay = this.$dayjs(
        this.localVermifuge.dateEnd
      ).format('L');
    }
  },
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    mdiCalendar() {
      return mdiCalendar;
    },
    localVermifuge: {
      get() {
        return this.value;
      }
    },
    localizedActivePrincipleList() {
      const LocalizedList: LocalizedSelectItem[] = [];
      for (const item of Object.values(ACTIVE_PRINCIPLES)) {
        let localizedDisease: LocalizedSelectItem = {
          label: this.$t(item),
          value: item
        };
        LocalizedList.push(localizedDisease);
      }
      return LocalizedList;
    }
  },
  props: {
    value: {
      type: Object as PropType<Vermifuge>,
      required: true
    }
  },
  watch: {
    localVermifuge: {
      handler(newVal) {
        this.$emit('input', newVal);
        if (!newVal.dateStart) {
          this.vermifugeDateDisplay = '';
        }
        if (!newVal.dateEnd) {
          this.vermifugeExpiryDateDisplay = '';
        }
      },
      deep: true
    },
    useOtherActivePrinciple: {
      handler(newVal) {
        if (!newVal) {
          this.otherActivePrinciple = '';
        }
      }
    },
    vermifugeDateDisplay: {
      handler(newVal) {
        if (newVal && this.localVermifuge.dateEnd) {
          const appliedDate = new Date(this.localVermifuge.dateStart);
          const expiryDate = new Date(this.localVermifuge.dateEnd);
          if (appliedDate > expiryDate) {
            this.localVermifuge.dateEnd = '';
            this.vermifugeExpiryDateDisplay = '';
          }
        }
      }
    },
    otherActivePrinciple: {
      handler(newVal, oldVal) {
        if (oldVal) {
          const id = this.localVermifuge.activePrinciple.indexOf(oldVal);
          this.localVermifuge.activePrinciple.splice(id, 1);
        }
        if (newVal) {
          this.localVermifuge.activePrinciple.push(newVal);
        }
      }
    }
  }
});
