
import Vue, { PropType } from "vue";
import {
  FOOD_DETAIL_COMPONENTS,
  FoodDetail,
  FoodDetailComponentType,
  FoodItem
} from "@/store/modules/food";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { FOOD_TYPES, FoodItemType, LocalizedSelectItem } from "@/helpers/types";
import vuelidateMixin from "@/helpers/vuelidateMixin";
import DatePicker from "@/components/horse/DatePicker.vue";

interface Props {
  value: FoodItem;
}

interface Data {
  foodUnitList: string[];
  foodDetailEther: FoodDetail;
  foodDetailProtein: FoodDetail;
}

interface Computed {
  localizedFoodPeriodList: LocalizedSelectItem[];
  localizedFoodCategoryList: LocalizedSelectItem[];
  localFormFoodItem: FoodItem;
  etherType: FoodDetailComponentType;
  proteinType: FoodDetailComponentType;
  rationType: FoodItemType;
  supplementType: FoodItemType;
  voluminousType: FoodItemType;
}

export default Vue.extend<Data, Record<string, never>, Computed, Props>({
  name: 'FoodForm',
  components: { DatePicker },
  mixins: [validationMixin, vuelidateMixin],
  validations: {
    localFormFoodItem: {
      category: { required },
      name: { required }
    }
  },
  data: function () {
    return {
      foodUnitList: ['mg', 'g', 'kg', 'ml', 'l'],
      foodDetailEther: {
        component: '',
        description: ''
      },
      foodDetailProtein: {
        component: '',
        description: ''
      }
    };
  },
  async created() {
    this.localFormFoodItem.detail = [];
    this.foodDetailEther.component = this.etherType.i18nCodeName;
    this.foodDetailProtein.component = this.proteinType.i18nCodeName;
  },
  computed: {
    localizedFoodPeriodList() {
      return [
        {
          label: this.$t('food-batch-card.periodicity.daily'),
          value: 'food-batch-card.periodicity.daily'
        },
        {
          label: this.$t('food-batch-card.periodicity.weekly'),
          value: 'food-batch-card.periodicity.weekly'
        },
        {
          label: this.$t('food-batch-card.periodicity.monthly'),
          value: 'food-batch-card.periodicity.monthly'
        }
      ];
    },
    localizedFoodCategoryList() {
      return [
        {
          label: this.$t(this.rationType.i18nCodeName),
          value: this.rationType.i18nCodeName
        },
        {
          label: this.$t(this.voluminousType.i18nCodeName),
          value: this.voluminousType.i18nCodeName
        },
        {
          label: this.$t(this.supplementType.i18nCodeName),
          value: this.supplementType.i18nCodeName
        }
      ];
    },
    localFormFoodItem: {
      get() {
        return this.value;
      }
    },
    etherType() {
      return FOOD_DETAIL_COMPONENTS.ETHER;
    },
    proteinType() {
      return FOOD_DETAIL_COMPONENTS.PROTEIN;
    },
    rationType() {
      return FOOD_TYPES.RATION;
    },
    supplementType() {
      return FOOD_TYPES.SUPPLEMENT;
    },
    voluminousType() {
      return FOOD_TYPES.VOLUMINOUS;
    }
  },
  watch: {
    localFormFoodItem: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true
    },
    foodDetailEther: {
      handler(newVal) {
        if (!newVal.description) {
          this.localFormFoodItem.detail?.forEach((item, index) => {
            if (
              item.component === this.etherType.i18nCodeName &&
              this.localFormFoodItem.detail
            ) {
              this.localFormFoodItem.detail.splice(index, 1);
            }
          });
        } else {
          if (
            !this.localFormFoodItem.detail?.find(
              (element) => element.component === this.etherType.i18nCodeName
            )
          ) {
            this.localFormFoodItem.detail?.push(this.foodDetailEther);
          }
        }
      },
      deep: true
    },
    foodDetailProtein: {
      handler(newVal) {
        if (!newVal.description) {
          this.localFormFoodItem.detail?.forEach((item, index) => {
            if (
              item.component === this.proteinType.i18nCodeName &&
              this.localFormFoodItem.detail
            ) {
              this.localFormFoodItem.detail.splice(index, 1);
            }
          });
        } else {
          if (
            !this.localFormFoodItem.detail?.find(
              (element) => element.component === this.proteinType.i18nCodeName
            )
          ) {
            this.localFormFoodItem.detail?.push(this.foodDetailProtein);
          }
        }
      },
      deep: true
    }
  },
  props: {
    value: {
      type: Object as PropType<FoodItem>,
      required: true
    }
  }
});
