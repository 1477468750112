
import Vue from 'vue';
import { Diet, FoodItem } from '@/store/modules/food';
import FoodDetailTable from '@/components/modules/food/FoodDetailTable.vue';
import { HeaderItem } from '@/views/Horses.vue';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { FOOD_TYPES, FoodItemType } from '@/helpers/types';
import { mdiInformation } from '@mdi/js';

interface Data {
  headers: HeaderItem[];
}

interface Methods {
  foodDetailHeader(food: FoodItem): TranslateResult;
  foodQtdAndFrequency(food: FoodItem): string;
  foodIcon(food: FoodItem): string;
}

interface Computed {
  rationType: FoodItemType;
  supplementType: FoodItemType;
  voluminousType: FoodItemType;
  mdiInformation: string;
}

interface Props {
  mainDiet: Diet;
  dense: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FoodTable',
  components: {
    FoodDetailTable
  },
  data: function () {
    return {
      headers: [
        {
          text: this.$i18n.tc('food-module-screen.provision-table.header.type'),
          value: 'category'
        },
        {
          text: this.$i18n.tc(
            'food-module-screen.provision-table.header.brand'
          ),
          value: 'brand'
        },
        {
          text: this.$i18n.tc('food-module-screen.provision-table.header.name'),
          value: 'name'
        },
        {
          text: this.$i18n.tc(
            'food-module-screen.provision-table.header.frequency'
          ),
          value: 'frequency'
        }
      ]
    };
  },
  methods: {
    foodQtdAndFrequency(food: FoodItem) {
      return this.$store.getters['FoodModule/getFoodQtdAndFrequency'](food);
    },
    foodDetailHeader(food: FoodItem) {
      if (
        food.category === this.rationType.i18nCodeName &&
        this.rationType.i18nCodeDetail
      ) {
        return this.$t(this.rationType.i18nCodeDetail);
      }
      if (
        food.category === this.supplementType.i18nCodeName &&
        this.supplementType.i18nCodeDetail
      ) {
        return this.$t(this.supplementType.i18nCodeDetail);
      }
      if (
        food.category === this.voluminousType.i18nCodeName &&
        this.voluminousType.i18nCodeDetail
      ) {
        return this.$t(this.voluminousType.i18nCodeDetail);
      }
      return '';
    },
    foodIcon(food: FoodItem) {
      switch (food.category) {
        case this.rationType.i18nCodeName:
          return this.rationType.icon;
        case this.supplementType.i18nCodeName:
          return this.supplementType.icon;
        case this.voluminousType.i18nCodeName:
          return this.voluminousType.icon;
        default:
          return '';
      }
    }
  },
  computed: {
    mdiInformation() {
      return mdiInformation;
    },
    rationType() {
      return FOOD_TYPES.RATION;
    },
    supplementType() {
      return FOOD_TYPES.SUPPLEMENT;
    },
    voluminousType() {
      return FOOD_TYPES.VOLUMINOUS;
    }
  },
  props: {
    mainDiet: {
      type: Object,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
});
