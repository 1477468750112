
import Vue from 'vue';
import { HorseShoe } from '@/store/modules/horseShoe';
import { shortStringDots } from '@/helpers/utils';

interface Methods {
  trimmedType(length: number): string;
}

interface Props {
  horseShoe: HorseShoe;
}

export default Vue.extend<
  Record<string, never>,
  Methods,
  Record<string, never>,
  Props
>({
  name: 'HorseShoeTable',
  methods: {
    trimmedType(n: number) {
      return shortStringDots(this.horseShoe.type, n);
    }
  },
  props: {
    horseShoe: {
      type: Object,
      required: true
    }
  }
});
