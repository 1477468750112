
import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { Vaccine } from '@/store/modules/health';
import { LocalizedSelectItem } from '@/helpers/types';
import vuelidateMixin from '@/helpers/vuelidateMixin';
import DatePicker from '@/components/horse/DatePicker.vue';
import { mdiPhone } from '@mdi/js';
import { mask } from 'vue-the-mask';

enum DISEASES {
  RAGE = 'health-batch-card.vaccine.diseases.rage',
  HERPES = 'health-batch-card.vaccine.diseases.herpes',
  RHODOCOCCUS = 'health-batch-card.vaccine.diseases.rhodococcus',
  LEPTOSPIROSIS = 'health-batch-card.vaccine.diseases.leptospirosis',
  INFLUENZA = 'health-batch-card.vaccine.diseases.influenza',
  TETANUS = 'health-batch-card.vaccine.diseases.tetanus',
  RHINOPNEUMONITIS = 'health-batch-card.vaccine.diseases.rhinopneumonitis',
  ENCEPHALOMYELITIS = 'health-batch-card.vaccine.diseases.encephalomyelitis'
}

interface Data {
  DISEASES: Record<string, unknown>;
}

interface Methods {
  isProtectionDisabled(disease: string): boolean;
}

interface Computed {
  localVaccine: Vaccine;
  localizedDiseasesList: LocalizedSelectItem[];
  mdiPhone: string;
}

interface Props {
  value: Vaccine;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AddVaccine',
  components: { DatePicker },
  directives: { mask },
  mixins: [validationMixin, vuelidateMixin],
  validations: {
    localVaccine: {
      product: { required },
      protectedAgainst: { required }
    }
  },
  data: () => ({
    DISEASES
  }),
  methods: {
    isProtectionDisabled(disease: string) {
      if (!this.localVaccine.protectedAgainst.length) {
        return false;
      }

      const singleProtected: string[] = [
        DISEASES.RAGE,
        DISEASES.HERPES,
        DISEASES.RHODOCOCCUS,
        DISEASES.LEPTOSPIROSIS
      ];

      if (singleProtected.includes(disease)) {
        return !this.localVaccine.protectedAgainst.includes(disease);
      }
      return this.localVaccine.protectedAgainst.some((d) =>
        singleProtected.includes(d)
      );
    }
  },
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    localVaccine: {
      get() {
        return this.value;
      }
    },
    localizedDiseasesList() {
      const LocalizedList: LocalizedSelectItem[] = [];
      for (const item of Object.values(DISEASES)) {
        let localizedDisease: LocalizedSelectItem = {
          label: this.$t(item),
          value: item
        };
        LocalizedList.push(localizedDisease);
      }
      return LocalizedList;
    }
  },
  props: {
    value: {
      type: Object as PropType<Vaccine>,
      required: true
    }
  },
  watch: {
    localVaccine: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true
    }
  }
});
