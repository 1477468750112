
import Vue, { PropType } from "vue";
import { HorseWeight } from "@/store/modules/horse";

interface Props {
  horseWeight: HorseWeight;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Record<string, never>,
  Props
>({
  name: 'WeightConfirmation',
  props: {
    horseWeight: {
      type: Object as PropType<HorseWeight>,
      required: true
    }
  }
});
