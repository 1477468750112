
import Vue, { PropType } from 'vue';
import { Vaccine } from '@/store/modules/health';
import { translateArray } from '@/helpers/utils';
import { mdiPhone } from '@mdi/js';

interface Computed {
  localizedDiseases: string;
  mdiPhone: string;
}

interface Props {
  vaccine: Vaccine;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'VaccineConfirmation',
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    localizedDiseases() {
      return translateArray(this.vaccine.protectedAgainst).join(', ');
    }
  },
  props: {
    vaccine: {
      type: Object as PropType<Vaccine>,
      required: true
    }
  }
});
