
import Vue from 'vue';
import { FoodDetail } from '@/store/modules/food';

interface Props {
  details: FoodDetail[];
  header: string;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Record<string, never>,
  Props
>({
  name: 'FoodDetailTable',

  props: {
    details: {
      type: Array,
      required: true
    },
    header: {
      type: String,
      required: true
    }
  }
});
