
import Vue, { PropType } from 'vue';
import { HealthReport } from '@/store/modules/health';
import { mdiCheckboxMarkedCircle, mdiPhone } from '@mdi/js';

interface Props {
  healthReport: HealthReport;
}

interface Computed {
  mdiPhone: string;
  mdiCheckboxMarkedCircle: string;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'DoneAppointmentConfirmation',
  props: {
    healthReport: {
      type: Object as PropType<HealthReport>,
      required: true
    }
  },
  computed: {
    mdiCheckboxMarkedCircle() {
      return mdiCheckboxMarkedCircle;
    },
    mdiPhone() {
      return mdiPhone;
    }
  }
});
