
import { MODULE_TYPES, ModuleItem } from "@/store/constants";
import Vue, { PropType } from "vue";
import { HorseItem } from "@/views/Horses.vue";
import BatchOperations from "@/components/BatchOperations.vue";
import { HorseShoe } from "@/store/modules/horseShoe";
import HorseShoeTable from "@/components/modules/horseShoe/HorseShoeTable.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import vuelidateMixin from "@/helpers/vuelidateMixin";
import { TYPE } from "@/store/modules/notification";
import { mdiCalendar } from "@mdi/js";

interface Data {
  valid: boolean;
  loading: boolean;
  horseShoe: HorseShoe;
  dateStartDisplay: string;
  dateStartPicker: string;
  daysForNext: number | null;
}

interface Methods {
  confirmBatch(): void;
  removeHorse(id: number): void;
  selectDate(): void;
}

interface Computed {
  horseShoeModule: ModuleItem;
  mdiCalendar: string;
}

interface Props {
  horseItems: HorseItem[];
  showNameBtn: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HorseShoeBatch',
  mixins: [validationMixin, vuelidateMixin],
  validations: {
    horseShoe: {
      type: { required }
    },
    dateStartDisplay: { required },
    daysForNext: { required }
  },
  data: () => ({
    valid: false,
    loading: false,
    dateStartPicker: new Date().toISOString().slice(0, 10),
    dateStartDisplay: '',
    daysForNext: null,
    horseShoe: {
      id: '',
      type: '',
      dateStart: new Date().toISOString().slice(0, 10),
      datePredictableEnd: new Date(
        new Date().getTime() + 30 * 24 * 60 * 60 * 1000
      ).toISOString(),
      observations: ''
    }
  }),
  components: {
    HorseShoeTable,
    BatchOperations
  },
  async created() {
    this.dateStartDisplay = this.$dayjs(this.horseShoe.dateStart).format('L');
  },
  methods: {
    confirmBatch() {
      this.loading = true;
      const horsesId = [];
      for (const horseItem of this.horseItems) {
        const horseId = horseItem.horse.id;
        horsesId.push(horseId);
      }
      this.$store
        .dispatch('HorseShoeModule/addHorseShoeToHorses', {
          horsesId: horsesId,
          horseShoe: this.horseShoe
        })
        .then(() => {
          this.daysForNext = null;
          this.horseShoe = {
            id: '',
            type: '',
            dateStart: new Date().toISOString(),
            datePredictableEnd: this.$dayjs(new Date())
              .add(this.daysForNext, 'day')
              .toISOString(),
            observations: ''
          };
          this.loading = false;
          this.dateStartDisplay = '';
          this.daysForNext = null;
          this.$v.$reset();
          this.$store.commit('NotificationModule/add', {
            msg: this.$t('horse-shoe.snackbar.success'),
            type: TYPE.SUCCESS
          });
          this.$emit('confirmed-batch');
        })
        .catch((error) => {
          //TODO @vini precisa ver como vc vai lidar com errors
          this.$rollbar.error(error);
        });
    },
    removeHorse(id) {
      this.$emit('remove-horse', id);
    },
    selectDate() {
      this.dateStartDisplay = this.$dayjs(this.dateStartPicker).format('L');
      const date = new Date(this.dateStartPicker);
      date.setHours(23, 59, 59);
      this.horseShoe.dateStart = date.toISOString();
      if (this.daysForNext) {
        this.horseShoe.datePredictableEnd = this.$dayjs(
          this.horseShoe.dateStart
        ).add(this.daysForNext, 'day').$d;
      }
    }
  },
  computed: {
    mdiCalendar() {
      return mdiCalendar;
    },
    horseShoeModule() {
      return MODULE_TYPES.HORSESHOE;
    }
  },
  props: {
    horseItems: {
      type: Array as PropType<HorseItem[]>,
      required: true
    },
    showNameBtn: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    daysForNext: {
      handler(newVal) {
        if (newVal && this.horseShoe.dateStart) {
          this.horseShoe.datePredictableEnd = this.$dayjs(
            this.horseShoe.dateStart
          ).add(this.daysForNext, 'day').$d;
        }
      }
    }
  }
});
