
import Vue from "vue";
import { mapState } from "vuex";
import { Horse } from "@/store/modules/horse";
import FoodBatch from "@/components/modules/food/FoodBatch.vue";
import HealthBatch from "@/components/modules/health/HealthBatch.vue";
import AvatarImageKit from "@/components/AvatarImageKit.vue";
import { DataItemProps } from "vuetify";
import HorseShoeBatch from "@/components/modules/horseShoe/HorseShoeBatch.vue";
import { showError } from "@/helpers/utils";
import { ErrorItem } from "@/helpers/errors";
import { mdiHorseVariant, mdiMagnify } from "@mdi/js";

export interface HorseItem {
  stableName: string;
  stableId: number;
  horse: Horse;
  additionalField?: string;
}

export interface HeaderItem {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
  mobile?: boolean;
  filterable?: boolean;
}

interface Data {
  horses: HorseItem[];
  selected: HorseItem[];
  headers: HeaderItem[];
  headersMobile: HeaderItem[];
  loading: boolean;
  search: string;
}

interface Methods {
  clearSearch(): void;
  clearHorses(): void;
  unselectHorse(id: number): void;
  rowClick(item: Record<string, unknown>, row: DataItemProps): void;
  showError(error: ErrorItem): void;
}

interface Computed {
  mdiHorseVariant: string;
  mdiMagnify: string;
}

export default Vue.extend<Data, Methods, Computed, Record<string, unknown>>({
  data: function () {
    return {
      search: '',
      loading: false,
      horses: [],
      selected: [],
      headers: [
        {
          text: this.$i18n.tc('horses.horses', 1),
          align: 'start',
          value: 'horse.name'
        },
        {
          text: this.$i18n.tc('horses.sex'),
          value: 'horse.sex'
        },
        {
          text: this.$i18n.tc('horses.stable', 1),
          value: 'stableName'
        },
        {
          text: this.$i18n.tc('horses.link-to-horse'),
          value: 'horseLink'
        },
        {
          text: this.$i18n.tc('horses.link-to-stable'),
          value: 'stableLink'
        }
      ],
      headersMobile: [
        {
          text: this.$i18n.tc('horses.horses', 1),
          align: 'start',
          sortable: false,
          value: 'horse.name'
        },
        {
          text: this.$i18n.tc('horses.stable', 1),
          value: 'stableName',
          sortable: false
        }
      ]
    };
  },
  components: {
    HorseShoeBatch,
    FoodBatch,
    HealthBatch,
    AvatarImageKit
  },
  async created() {
    const stables = this.$store.getters['StableModule/getAll'];
    for (const stable of stables) {
      for (const horse of stable.horses) {
        const iHorse: HorseItem = {
          stableName: stable.name,
          stableId: stable.id,
          horse: this.$store.getters['HorseModule/getById'](horse)
        };
        this.horses.push(iHorse);
      }
    }
  },
  methods: {
    clearSearch() {
      this.search = '';
    },
    clearHorses() {
      this.selected = [];
    },
    unselectHorse(id) {
      this.selected.splice(id, 1);
    },
    rowClick(item, row) {
      const selectState = !row.isSelected;
      row.select(selectState);
    },
    showError(error) {
      showError(error);
    }
  },
  computed: {
    ...mapState('UserModule', ['currentSpace']),
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    mdiMagnify() {
      return mdiMagnify;
    }
  }
});
