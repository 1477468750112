import { render, staticRenderFns } from "./Horses.vue?vue&type=template&id=1014bb58&scoped=true"
import script from "./Horses.vue?vue&type=script&lang=ts"
export * from "./Horses.vue?vue&type=script&lang=ts"
import style0 from "./Horses.vue?vue&type=style&index=0&id=1014bb58&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1014bb58",
  null
  
)

export default component.exports