
import Vue, { PropType } from "vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { LocalizedSelectItem } from "@/helpers/types";
import vuelidateMixin from "@/helpers/vuelidateMixin";
import DatePicker from "@/components/horse/DatePicker.vue";
import { mask } from "vue-the-mask";
import { HorseWeight } from "@/store/modules/horse";

enum WEIGHING_TYPES {
  SCALE = 'health-batch-card.weight.types.scale',
  TAPE = 'health-batch-card.weight.types.weighing-tape'
}

interface Data {
  WEIGHING_TYPES: Record<string, unknown>;
}

interface Computed {
  localWeight: HorseWeight;
  localizedWeighingTypeList: LocalizedSelectItem[];
}

interface Props {
  value: HorseWeight;
}

export default Vue.extend<Data, Record<string, never>, Computed, Props>({
  name: 'AddWeight',
  components: { DatePicker },
  directives: { mask },
  mixins: [validationMixin, vuelidateMixin],
  validations: {
    localWeight: {
      weight: { required },
      weighingType: { required }
    }
  },
  data: () => ({
    WEIGHING_TYPES
  }),
  computed: {
    localWeight: {
      get() {
        return this.value;
      }
    },
    localizedWeighingTypeList() {
      const LocalizedList: LocalizedSelectItem[] = [];
      for (const item of Object.values(WEIGHING_TYPES)) {
        let localizedDisease: LocalizedSelectItem = {
          label: this.$t(item),
          value: item
        };
        LocalizedList.push(localizedDisease);
      }
      return LocalizedList;
    }
  },
  props: {
    value: {
      type: Object as PropType<HorseWeight>,
      required: true
    }
  },
  watch: {
    localWeight: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true
    }
  }
});
