
import Vue, { PropType } from "vue";
import { ModuleItem } from "@/store/constants";
import { HorseItem } from "@/views/Horses.vue";
import AvatarImageKit from "@/components/AvatarImageKit.vue";
import { mdiBarn, mdiClose, mdiHorseVariant, mdiPlus } from "@mdi/js";

interface Props {
  horseItems: HorseItem[];
  type: ModuleItem;
  valid: boolean;
  showNameBtn: boolean;
}

interface Methods {
  closeModal(): void;
  confirmBatch(): void;
  removeHorse(id: number): void;
  validateForms(): void;
}

interface Data {
  mainDialog: boolean;
  confirmDialog: boolean;
}

interface Computed {
  mdiClose: string;
  mdiHorseVariant: string;
  mdiBarn: string;
  mdiPlus: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'BatchOperations',
  components: {
    AvatarImageKit
  },
  data: () => ({
    mainDialog: false,
    confirmDialog: false
  }),
  computed: {
    mdiClose() {
      return mdiClose;
    },
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    mdiBarn() {
      return mdiBarn;
    },
    mdiPlus() {
      return mdiPlus;
    }
  },
  methods: {
    closeModal() {
      this.mainDialog = false;
    },
    confirmBatch() {
      this.mainDialog = false;
      this.confirmDialog = false;
      this.$emit('confirmed-batch-from-dialog');
    },
    removeHorse(id) {
      this.$emit('remove-horse', id);
    },
    validateForms() {
      this.$emit('validate-forms');
      if (this.valid) {
        this.confirmDialog = true;
      }
    }
  },
  props: {
    horseItems: {
      type: Array as PropType<HorseItem[]>,
      required: true
    },

    type: {
      type: Object as PropType<ModuleItem>,
      required: true
    },
    valid: {
      type: Boolean,
      default: true
    },
    showNameBtn: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    valid(newValue) {
      if (!newValue) this.confirmDialog = false;
    }
  }
});
