
import Vue, { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import { mask } from 'vue-the-mask';
import { required } from 'vuelidate/lib/validators';
import { HealthReport } from '@/store/modules/health';
import vuelidateMixin from '@/helpers/vuelidateMixin';
import DatePicker from '@/components/horse/DatePicker.vue';
import { mdiPhone } from '@mdi/js';

interface Methods {
  selectDateHealthReport(date: string): void;
}

interface Computed {
  localHealthReport: HealthReport;
  mdiPhone: string;
}

interface Props {
  value: HealthReport;
}

export default Vue.extend<Record<string, never>, Methods, Computed, Props>({
  name: 'AddDoneAppointment',
  components: { DatePicker },
  directives: { mask },
  mixins: [validationMixin, vuelidateMixin],
  validations: {
    localHealthReport: {
      lastEntry: {
        dateStart: { required }
      }
    }
  },
  async created() {
    this.localHealthReport.lastEntry.anamnesis = this.$t(
      'health-module-screen.health-report.normal-report.anamnesis'
    ).toString();
    this.localHealthReport.lastEntry.clinicalExam.description = this.$t(
      'health-module-screen.health-report.normal-report.clinical-exam'
    ).toString();
    this.localHealthReport.lastEntry.conclusion = this.$t(
      'health-module-screen.health-report.normal-report.conclusion'
    ).toString();
  },
  methods: {
    selectDateHealthReport(date) {
      this.localHealthReport.dateStart = date;
      this.localHealthReport.dateEnd = date;
      this.localHealthReport.lastEntry.dateStart = date;
    }
  },
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    localHealthReport: {
      get() {
        return this.value;
      }
    }
  },
  props: {
    value: {
      type: Object as PropType<HealthReport>,
      required: true
    }
  },
  watch: {
    localHealthReport: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true
    }
  }
});
