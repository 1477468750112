
import Vue, { PropType } from 'vue';
import { Vermifuge } from '@/store/modules/health';
import { translateArray } from '@/helpers/utils';
import { mdiPhone } from '@mdi/js';

interface Computed {
  localizedActivePrinciple: string;
  mdiPhone: string;
}

interface Props {
  vermifuge: Vermifuge;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'VermifugeConfirmation',
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    localizedActivePrinciple() {
      return translateArray(this.vermifuge.activePrinciple).join(', ');
    }
  },
  props: {
    vermifuge: {
      type: Object as PropType<Vermifuge>,
      required: true
    }
  }
});
